.filtro-contenedor {
  display: flex;
}

.filtro-contenedor .filtro-item {
  width: 100%;
  padding-right: 10px;
}

.filtro-contenedor .filtro-item.boton {
  display: flex;
  align-items: flex-end;
  padding: 0px 10px;
}

.filtro-contenedor .filtro-item .etiqueta {
  display: block;
}

.filtro-contenedor .filtro-item .control {
  /* margin-left: 10px;  */
  width: 100%;
}
