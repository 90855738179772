@import "~antd/dist/antd.less";

body {
  background: #f4f4f4;
}

// svg {
//   overflow: hidden;
//   vertical-align: inherit;
// }

.ant-card-bordered {
  border: 1px solid #ddd;
}

.ant-card-head {
  border-bottom: 1px solid #ddd;
}

/* Login CSS */
.container-login {
  min-height: 100vh;
  background-color: #ffffff;
}

.container-login__imagen {
  background-image: url("https://storage.googleapis.com/milegajodigital.com/admin/publico/fondo.png");
  background-size: cover;
  background-position: center;
}

.container-login__formulario {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.container-login__formulario>.login {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.container-login__formulario>.login>.logotipo {
  text-align: center;
}

.container-login__formulario>.login>.logotipo>.imagen {
  max-width: 100%;
  max-height: 120px;
}

.container-login__formulario>.login>.logotipo>.imagen-derecha {
  display: block;
  margin: 0 auto;
  background-repeat: no-repeat;
  margin-bottom: 60px;
  max-width: 100%;
  max-height: 120px;
}

.container-login__formulario>.login>.texto {
  text-align: center;
  margin-bottom: 24px;
}

/* Plantilla Administrador CSS */
.layout {
  background: #f4f4f4;
}

.layout-header {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.layout-header__logotipo {
  padding-right: 15px;
}

.layout-header__logotipo-imagen {
  height: 32px;
  padding-right: 10px;
}

.layout-header__menu {
  flex: 1 1 0%;
  min-width: 0;
}

.layout-header__menu-perfil {
  cursor: pointer;
}

.layout-content {
  padding: 30px 50px 30px 50px;
  height: calc(100vh - 64px - 70px);
}

.layout-footer {
  text-align: center;
  background: #f4f4f4;
}

.ant-card .ant-card-body .container-crear-titulo {
  text-align: center;
  font-size: 32px;
  line-height: 1.8;
}

.ant-card .ant-card-body .container-crear-descripcion {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-size: 14px;
}

.milegajo-fila-tabla-inactiva {
  background-color: rgba(110, 119, 136, 0.08);
}

.detalle-envio_registro-eliminado {
  background-color: #f2f2f2;
}

.listar-legajo-colaborador-seleccionado {
  color: #1890ff;
}

.listar-legajo-colaborador-seleccionado:hover {
  color: #1890ff;
}

.listar-legajo-colaborador-noseleccionado:hover {
  color: #1890ff;
}

.listar-legajo-colaborador-noseleccionado {
  color: #868789;
}

.div-colaborador-legajo {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f1f1;
}

.listar-legajo-colaborador-documento-seleccionado {
  color: #1890ff;
}

.listar-legajo-colaborador-documento-noseleccionado {
  color: #868789;
}

.listar-legajo-colaborador-documento {
  margin-left: 20%;
}

.ant-table {
  color: #7f7f7f;
}

// .ant-table-thead > tr > th {
//   color: #1678c1;
//   background: #ffffff;
//   border-bottom: 1px solid #1678c1;
// }

// .ant-table-small .ant-table-thead > tr > th {
//   background-color: #ffffff;
//   border-bottom: 1px solid #fafafa;
// }

//custom color elementos
.milegajo-color-primary {
  color: #1678c1;
}

.custom-color-element {
  color: #1678c1 !important;
}

.ant-menu {
  color: #1678c1;
  font-weight: 500;
}

.ant-btn-primary {
  background: #1678c1;
  border-color: #1678c1;
}

/*
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #1678c1;
  // border-bottom: 3px solid #1678c1;
}
*/

.texto-menu-perfil {
  font-weight: 500;
}

// .container-dashboard {
//   border: 1px solid red;
//   height: calc(100vh - 180px);
// }
//Espacio a la derecha

.space-right {
  padding-right: 10px;
}

.ant-picker-input-customize {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.milegajo-modal-body__titulo {
  display: block;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}

.milegajo-card-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.milegajo-card-selector {
  cursor: pointer;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.milegajo-card-selector.activo {
  border: 1px solid rgb(10, 132, 174);
  box-shadow: rgb(10, 132, 174) 0px 0px 0px 1px;
  outline: none;
}

.milegajo-card-selector__titulo {
  font-size: 14px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding-bottom: 3px;
  padding-top: 3px;
  margin-top: 4px;
}

.milegajo-card-selector__cuerpo {
  font-size: 13px;
  color: rgb(117, 117, 117);
  line-height: 1.6;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-left: 5%;
  padding-right: 5%;
}

.milegajo-link-table {
  color: #585858;
  padding: 0px;
}

.milegajo-menu-table {
  color: #585858;
}

.milegajo-menu-item:hover {
  background: #e6f7ff;
}

.milegajo-ant-tag {
  margin-right: 0px;
}

.milegajo-ant-tag.pointer {
  cursor: pointer;
}

.milegajo-icono-pregunta {
  display: block;
  font-size: 40px;
  color: #ff9800;
}

.milegajo-texto-pregunta {
  display: block;
  text-align: center;
  font-size: 18px;
  color: #3a3737;
  margin-top: 10px;
}

.milegajo-icono-dragger {
  font-size: 40px;
  color: #197ec2;
  margin-bottom: 5px;
}

.milegajo-texto-dragger {
  display: block;
  text-align: center;
  color: #797575;
  font-size: 16px;
  font-weight: 500;
}

.milegajo-resaltar-texto-alert-info {
  color: #197ec2;
}

.milegajo-visor-pdf-header-separador {
  width: 1px;
  display: inline-block;
  height: 32px;
  background: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  margin: 0 8px;
}

.milegajo-visor-pdf-header-botones {
  margin-left: 10px;
}

.milegajo-ant-modal {
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-content {
    height: 100%;
  }
}

.milegajo-visor-pdf-body {
  height: calc(100% - 64px);
  background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    // font-size: 40px;
    // color: #2b81de;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    z-index: 1000;

    .spin {
      font-size: 40px;
      color: #2b81de;
    }
  }

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    &__Page {
      max-width: calc(~"100% - 2em");
      margin-top: 1em;

      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}

.milegajo-visor-pdf-header {
  min-height: 64px;
  // border-bottom: 1px solid rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-width: 0;

  .informacion {
    display: flex;
    align-items: center;

    .icono {
      font-size: 30px;
      color: #2b81de;
    }

    .texto {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      font-size: 12px;

      .periodo {
        color: black;
        font-weight: bold;
      }

      .documento {
        color: rgba(var(--sk_foreground_max, 29, 28, 29), 0.7);
        font-weight: bold;
      }
    }
  }

  .acciones {
    display: flex;

    .firmar {
      padding: 0 50px;
      margin-left: 15px;
      margin-right: 10px;
    }

    .consultar-rrhh {
      margin-left: 15px;
    }

    .cerrar {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.milegajo-visor-pdf-firma-titulo-firma {
  background: #f6f6f6;
  min-height: 100px;
}

.milegajo-visor-pdf-firma-body {
  overflow: auto;
  height: calc(100% - 64px);
  background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading {
    // font-size: 40px;
    // color: #2b81de;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    z-index: 1000;

    .spin {
      font-size: 40px;
      color: #2b81de;
    }
  }

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    &__Page {
      // max-width: calc(~"100% - 2em");
      // margin-top: 1em;

      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}

.milegajo-imagen-firma {
  // width: 232px;
  // height: 50px;
  cursor: move;
}

// .milegajo-imagen-firma:hover {
//   background: rgba(74, 153, 228, 0.603);
// }

.milegajo-visor-pdf-firma-header {
  min-height: 64px;
  border-bottom: 1px solid rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-width: 0;

  .informacion {
    display: flex;
    align-items: center;

    .icono {
      font-size: 30px;
      color: #2b81de;
    }

    .texto {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      font-size: 12px;

      .periodo {
        color: black;
        font-weight: bold;
      }

      .documento {
        color: rgba(var(--sk_foreground_max, 29, 28, 29), 0.7);
        font-weight: bold;
      }
    }
  }

  .acciones {
    display: flex;

    .firmar {
      padding: 0 50px;
      margin-left: 15px;
      margin-right: 10px;
    }

    .consultar-rrhh {
      margin-left: 15px;
    }

    .cerrar {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.pagina-react-pdf {
  padding-bottom: 20px;
}

.requerido {
  padding: 0px 5px;
  color: rgb(170, 37, 37);
}

.resumen-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin: 0;
}

.resumen-skeleton .ant-skeleton-content .ant-skeleton-paragraph>li {
  height: 8px;
}

.resumen-skeleton .ant-skeleton-content .ant-skeleton-paragraph>li+li {
  margin-top: 8px;
}

/* legajo colaborador */
.contenedor-legajo {
  display: flex;
  //height: calc(100vh - 170px);
}

.contenedor-legajo .container-colaboradores {
  margin-right: 10px;
  width: 25%;
}

.contenedor-legajo .container-colaboradores .lista-colaboradores {
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid #e8e8e8;
  height: calc(100% - 73px);
  overflow: auto;
}

.contenedor-legajo .lista-documentos {
  width: 75%;
  height: 100%;
}

.card-container-legajo {
  height: 100%;
}

.card-container-legajo>.ant-tabs-card .ant-tabs-content {
  height: calc(100% + 16px);
  margin-top: -16px;
}

.card-container-legajo>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container-legajo>.ant-tabs-card>.ant-tabs-nav::before {
  display: none;
}

.formulario-visor-pdf {
  //height: calc(100% - 64px);
  height: 100%;
  background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    // font-size: 40px;
    // color: #2b81de;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    height: calc(100% - 64px);
    width: 100%;
    position: absolute;
    z-index: 1000;

    .spin {
      font-size: 40px;
      color: #2b81de;
    }
  }

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    &__Page {

      //max-width: calc(~"100% - 2em");
      // margin-top: 2em;
      // border: 1px solid green;
      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}

.eform-element:hover {
  // outline: 1px solid #1890ff;
  background: #ffbf4f !important;
}

.eform-element:focus {
  // outline: 1px solid #1890ff !important;
  background: #ffbf4f !important;
}

.resumen-documentos {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 12px;
  width: 30%;
  color: gray;
}

.onboarding-firmantes .ant-table {
  font-size: 12px;
}

.visor-formulario-generico-body {
  background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  display: flex;
  justify-content: center;
  align-items: center;

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    &__Page {
      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}

.visor-formulario-flujo-firma {
  background: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  display: flex;
  justify-content: center;
  align-items: center;

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      // overflow: auto;
    }

    &__Page {
      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}


// eliminar
.cube {
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin: 4px;
  background: skyblue;
  --color: #4af;
  color: #333;
  line-height: 40px;
  text-align: center;
}
@layout-header-background: #FFFFFF;